import $ from 'jquery';
import NavbarPromo from '../core/components/navbar-promo';
import Navbar from './navbar';
import { getCurrentBreakpoint } from '../utils/breakpoint';
import { Breakpoint } from '../types/breakpoint';
import TrapFocus from '../core/components/trap-focus';
export default class StickyBar {
    static $element = $('.js__sticky-bar');
    static $target = $('.js__sticky-bar-target');
    static init() {
        if (this.$element.length) {
            this.setTarget();
            this.checkTargetVisibility();
            $(window).on('resize', () => {
                this.checkTargetVisibility();
            });
        }
    }
    static getStickyBarHeight() {
        return this.$element.outerHeight();
    }
    static checkTargetVisibility() {
        if (!this.$target.length) {
            return;
        }
        const observer = new IntersectionObserver(entries => {
            const isTargetBelowViewport = entries[0].boundingClientRect.top > 0;
            const isTargetAboveViewport = entries[0].boundingClientRect.bottom < 0;
            const isTargetOutsideViewport = entries[0].intersectionRatio === 0;
            const navbarPromoHeight = NavbarPromo.getNavBarPromoHeight() || 0;
            const navbarHeight = Navbar.getNavbarHeight() || 0;
            const isStickyBarVisible = isTargetOutsideViewport &&
                (this.$element.data('sticky-below-target') ? isTargetAboveViewport : true) &&
                (this.$element.data('sticky-above-target') ? isTargetBelowViewport : true);
            const isStickyMobileBottom = this.$element.data('sticky-mobile-bottom');
            const currentBreakpoint = getCurrentBreakpoint();
            if (isStickyMobileBottom && currentBreakpoint < Breakpoint.lg) {
                this.$element.css({ bottom: 0 });
                this.$element.css({ top: 'initial' });
            }
            else {
                this.$element.css({ top: navbarPromoHeight + navbarHeight });
                this.$element.css({ bottom: 'initial' });
            }
            this.$element.toggleClass('sticky-bar-visible', isStickyBarVisible);
            if (isStickyBarVisible) {
                TrapFocus.addTabIndexInElement(this.$element, 'sticky-bar');
            }
            else {
                TrapFocus.removeTabIndexInElement(this.$element, null, 'sticky-bar');
            }
        });
        observer.observe(this.$target[0]);
    }
    static setTarget() {
        if (!this.$target.length) {
            this.$target = $(this.$element.data('sticky-target-selector'));
        }
    }
}
